<template>
  <v-card class="elevation-12">
    <v-toolbar color="orange darken-3" dark flat>
      <v-toolbar-title>{{ $t("logout.pageTitle") }}</v-toolbar-title>
    </v-toolbar>
    <v-card-text> {{ $t("logout.body") }}</v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="$router.push('/')">{{ $t("logout.backToLogin") }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { clearLogoutTimer } from "../../helpers/timeout.js";

export default {
  data() {
    return {};
  },
  mounted() {
    clearLogoutTimer();
    this.$store.dispatch("logout");
  },
  methods: {},
};
</script>
